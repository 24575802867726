<template class="text_google">
  <v-app>
    <v-system-bar
      class="noprint"
      color="grey darken-2"
      dark
      height="40"
      app
      lights-out
    >
      <v-toolbar-items class="ml-5">
        <v-btn
          href="javascript:window.print()"
          class="title elevation-0 mr-2"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>
        <v-btn
          @click.stop="Export2Doc('exportContent', 'word-content')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div align="center">
          <!--   <v-img max-height="30mm" max-width="25mm" src="krud.jpg"></v-img> -->
          <div class="head">
            แบบแจ้งข้อมูลกลุ่มวิชา/ทาง ที่ใช้ในการบรรจุผู้สอบแข่งขันได้
            ตำแหน่งครูผู้ช่วย ประจำปี
            {{ college_manpower_reportss.college_manpower_reportyear }} ครั้งที่
            {{ college_manpower_reportss.college_manpower_reporttime }}
          </div>
          <div class="head">
            ส่งมาพร้อมกับหนังสือ
            {{ colleges_user.college_name }}
            ที่...........................ลงวันที่
            ..................................
          </div>
          <div>___________________________</div>
        </div>

        <table width="100%" align="center" class="table" small>
          <thead>
            <tr>
              <th class="regular16 th text-center" rowspan="2">
                ที่
              </th>
              <th class="regular16 th text-center" rowspan="2">
                จังหวัด
              </th>
              <th class="regular16 th text-center" rowspan="2">
                สถานศึกษา
              </th>
              <th class="regular16 th text-center" rowspan="2">
                เลขที่ตำแหน่ง
              </th>
              <th width="15%" class="regular16 th text-center" rowspan="2">
                รายชื่อกลุ่มวิชา/ทาง ที่ประสงค์บรรจุ
              </th>
              <th class="regular16 th text-center" rowspan="2">
                เหตุผลที่ประสงค์บรรจุฯ กลุ่มวิชานี้
              </th>
              <th class="regular16 th text-center" colspan="4">
                จำนวนผู้เรียนในกลุ่มสาขาวิชาที่เลือก
              </th>
              <th class="regular16 th text-center" colspan="4">
                จำนวนครูในกลุ่มสาขาวิชาที่เลือก
              </th>
              <th class="regular16 th text-center" colspan="4">จำนวนผู้เรียนทั้งหมด</th>
              <th class="regular16 th text-center" rowspan="2">หมายเหตุ</th>
            </tr>
            <tr>
              <td class="regular16 th text-center">ปวช.</td>
              <td class="regular16 th text-center">ปวส.</td>
              <td class="regular16 th text-center">ระยะสั่น</td>
              <td class="regular16 th text-center">รวม</td>


              <td class="regular16 th text-center">ข้าราชการครู</td>
              <td class="regular16 th text-center">พนักงานราชการ(ครู)</td>
              <td class="regular16 th text-center">ครูจ้างฯ</td>
              <td class="regular16 th text-center">รวม</td>

              <td class="regular16 th text-center">ปวช.</td>
              <td class="regular16 th text-center">ปวส.</td>
              <td class="regular16 th text-center">ระยะสั่น</td>
              <td class="regular16 th text-center">รวม</td>


            </tr>
          </thead>
          <tbody v-for="(item,index) in jor18s" :key="item.id">
            <tr>
              <td class="regular12 th text-center pa-1">
                {{ index+1 }}
              </td>
              <td class="regular12 th pa-1">
                {{ item.province_name }}
              </td>

              <td class="regular12 th pa-1">
                {{ item.college_name }}
              </td>
              
              <td class="regular12 th text-center">
                {{ item.id_position }}
              </td>
              
              <td class="regular12 th pl-1">
                {{ item.college_manpower_idbranch }} : {{ item.name_branch }}
              </td>
              <td class="regular12 th text-center">
                {{ item.college_manpower_comment }}
              </td>
              <td class="regular12 th text-center">
                {{ item.college_manpower_note }}
              </td>
            </tr>
          </tbody>
        </table>

        <table border="0" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td width="40%">
              <div class="regular16">
                <span class="regular16"
                  >ข้อมูล ณ วันที่
                  {{
                    college_manpower_reportss.college_manpower_reporttimestamp
                      | moment("add", "543 year")
                      | moment("D MMMM YYYY")
                  }}</span
                >
              </div>
            </td>
          </tr>
        </table>

        <br />
        <table border="0" width="100%" align="center" class="text_j">
          <tr>
            <td width="60%"></td>
            <td width="40%">
              <div class="regular16">
                ลงชื่อ.............................................................
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">
                (..........................................................)
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">ผู้รายงาน</div>
            </td>
          </tr>
        </table>

        <br /><br />
        <table border="0" width="100%" align="center" class="text_j">
          <tr>
            <td width="60%"></td>
            <td width="40%">
              <div class="regular16">
                ลงชื่อ.............................................................
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">
                (.....{{ colleges_user.collegeinfo_director }}.....)
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">
                ผู้อำนวยการ{{ colleges_user.college_name }}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    personnel_temporarys: [],
    transference_personnels: [],
    personnel_educations: [],
    transference_locations: [],
    college_manpower_reportss: [],
    jor18s: [],
    personnel_temporarys_switch: [],
    province_sh_s: [],
    province_sh_fm: [],
    province_sh_m: [],
    colleges_user: [],
    valid: true,
    user: {},
    loading: true,
    dialogLoading: true,
    interval: {},
    value: 0
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.college_manpower_reportsQueryAll();
    await this.j18Query();
    await this.collegeQueryAll();

    Swal.close();
  },
  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async collegeQueryAll() {
      this.loading = true;
      let result = await this.$http.post("college.php", {
        ApiKey: this.ApiKey,
        college_code: this.college_manpower_reportss
          .college_manpower_reportcollege
      });
      this.colleges_user = result.data;
    },

    async college_manpower_reportsQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college_manpower_report.php", {
          ApiKey: this.ApiKey,
          college_manpower_reportref: this.college_manpower_reportref
        })
        .finally(() => (this.loading = false));
      this.college_manpower_reportss = result.data;
    },

    async j18Query() {
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey,
          college_manpower_ref: this.college_manpower_reportref,
          j18college_code: this.college_manpower_reportss
            .college_manpower_reportcollege,
          idpostion_null: "Ok"
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;
      let data = result.data;
      let counta = 0;
      data.forEach(value => {
        counta += 1;
      });
      this.college_manCount = counta;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    college_manpower_reportref() {
      return this.$route.query.college_manpower_reportref;
    },

    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },
    college_manpower_reportyear() {
      const d = new Date();
      let year = d.getFullYear();
      return year;
    },
    college_manpower_reporttime() {
      let time_m;
      if (this.month_now > 2) {
        time_m = 2;
      } else {
        time_m = 1;
      }
      return time_m;
    }
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.div-indent {
  text-indent: 3em;
  line-height: 1;
}
.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 29.7cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
    height: 247mm;
  }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1.5cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
  padding-left: 1cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
      font-size: 18px;
      font-weight: bold;
    }
    .regular12 {
      font-size: 12px;
    }
    .blod12 {
      font-size: 12px;
      font-weight: bold;
    }
    .blackRegula8 {
      font-size: 8px;
    } */
  .noprint {
    display: none;
  }
}
</style>
